const langcode = [
  { value: 'af', text: 'Afrikaans' },
  { value: 'af-ZA', text: 'Afrikaans (South Africa)' },
  { value: 'ar', text: 'Arabic' },
  { value: 'ar-AE', text: 'Arabic (U.A.E.)' },
  { value: 'ar-BH', text: 'Arabic (Bahrain)' },
  { value: 'ar-DZ', text: 'Arabic (Algeria)' },
  { value: 'ar-EG', text: 'Arabic (Egypt)' },
  { value: 'ar-IQ', text: 'Arabic (Iraq)' },
  { value: 'ar-JO', text: 'Arabic (Jordan)' },
  { value: 'ar-KW', text: 'Arabic (Kuwait)' },
  { value: 'ar-LB', text: 'Arabic (Lebanon)' },
  { value: 'ar-LY', text: 'Arabic (Libya)' },
  { value: 'ar-MA', text: 'Arabic (Morocco)' },
  { value: 'ar-OM', text: 'Arabic (Oman)' },
  { value: 'ar-QA', text: 'Arabic (Qatar)' },
  { value: 'ar-SA', text: 'Arabic (Saudi Arabia)' },
  { value: 'ar-SY', text: 'Arabic (Syria)' },
  { value: 'ar-TN', text: 'Arabic (Tunisia)' },
  { value: 'ar-YE', text: 'Arabic (Yemen)' },
  { value: 'az', text: 'Azeri (Latin)' },
  { value: 'az-AZ', text: 'Azeri (Latin) (Azerbaijan)' },
  { value: 'az-AZ', text: 'Azeri (Cyrillic) (Azerbaijan)' },
  { value: 'be', text: 'Belarusian' },
  { value: 'be-BY', text: 'Belarusian (Belarus)' },
  { value: 'bg', text: 'Bulgarian' },
  { value: 'bg-BG', text: 'Bulgarian (Bulgaria)' },
  { value: 'bs-BA', text: 'Bosnian (Bosnia and Herzegovina)' },
  { value: 'ca', text: 'Catalan' },
  { value: 'ca-ES', text: 'Catalan (Spain)' },
  { value: 'cs', text: 'Czech' },
  { value: 'cs-CZ', text: 'Czech (Czech Republic)' },
  { value: 'cy', text: 'Welsh' },
  { value: 'cy-GB', text: 'Welsh (United Kingdom)' },
  { value: 'da', text: 'Danish' },
  { value: 'da-DK', text: 'Danish (Denmark)' },
  { value: 'de', text: 'German' },
  { value: 'de-AT', text: 'German (Austria)' },
  { value: 'de-CH', text: 'German (Switzerland)' },
  { value: 'de-DE', text: 'German (Germany)' },
  { value: 'de-LI', text: 'German (Liechtenstein)' },
  { value: 'de-LU', text: 'German (Luxembourg)' },
  { value: 'dv', text: 'Divehi' },
  { value: 'dv-MV', text: 'Divehi (Maldives)' },
  { value: 'el', text: 'Greek' },
  { value: 'el-GR', text: 'Greek (Greece)' },
  { value: 'en', text: 'English' },
  { value: 'en-AU', text: 'English (Australia)' },
  { value: 'en-BZ', text: 'English (Belize)' },
  { value: 'en-CA', text: 'English (Canada)' },
  { value: 'en-CB', text: 'English (Caribbean)' },
  { value: 'en-GB', text: 'English (United Kingdom)' },
  { value: 'en-IE', text: 'English (Ireland)' },
  { value: 'en-JM', text: 'English (Jamaica)' },
  { value: 'en-NZ', text: 'English (New Zealand)' },
  { value: 'en-PH', text: 'English (Republic of the Philippines)' },
  { value: 'en-TT', text: 'English (Trinidad and Tobago)' },
  { value: 'en-US', text: 'English (United States)' },
  { value: 'en-ZA', text: 'English (South Africa)' },
  { value: 'en-ZW', text: 'English (Zimbabwe)' },
  { value: 'eo', text: 'Esperanto' },
  { value: 'es', text: 'Spanish' },
  { value: 'es-AR', text: 'Spanish (Argentina)' },
  { value: 'es-BO', text: 'Spanish (Bolivia)' },
  { value: 'es-CL', text: 'Spanish (Chile)' },
  { value: 'es-CO', text: 'Spanish (Colombia)' },
  { value: 'es-CR', text: 'Spanish (Costa Rica)' },
  { value: 'es-DO', text: 'Spanish (Dominican Republic)' },
  { value: 'es-EC', text: 'Spanish (Ecuador)' },
  { value: 'es-ES', text: 'Spanish (Castilian)' },
  { value: 'es-ES', text: 'Spanish (Spain)' },
  { value: 'es-GT', text: 'Spanish (Guatemala)' },
  { value: 'es-HN', text: 'Spanish (Honduras)' },
  { value: 'es-MX', text: 'Spanish (Mexico)' },
  { value: 'es-NI', text: 'Spanish (Nicaragua)' },
  { value: 'es-PA', text: 'Spanish (Panama)' },
  { value: 'es-PE', text: 'Spanish (Peru)' },
  { value: 'es-PR', text: 'Spanish (Puerto Rico)' },
  { value: 'es-PY', text: 'Spanish (Paraguay)' },
  { value: 'es-SV', text: 'Spanish (El Salvador)' },
  { value: 'es-UY', text: 'Spanish (Uruguay)' },
  { value: 'es-VE', text: 'Spanish (Venezuela)' },
  { value: 'et', text: 'Estonian' },
  { value: 'et-EE', text: 'Estonian (Estonia)' },
  { value: 'eu', text: 'Basque' },
  { value: 'eu-ES', text: 'Basque (Spain)' },
  { value: 'fa', text: 'Farsi' },
  { value: 'fa-IR', text: 'Farsi (Iran)' },
  { value: 'fi', text: 'Finnish' },
  { value: 'fi-FI', text: 'Finnish (Finland)' },
  { value: 'fo', text: 'Faroese' },
  { value: 'fo-FO', text: 'Faroese (Faroe Islands)' },
  { value: 'fr', text: 'French' },
  { value: 'fr-BE', text: 'French (Belgium)' },
  { value: 'fr-CA', text: 'French (Canada)' },
  { value: 'fr-CH', text: 'French (Switzerland)' },
  { value: 'fr-FR', text: 'French (France)' },
  { value: 'fr-LU', text: 'French (Luxembourg)' },
  { value: 'fr-MC', text: 'French (Principality of Monaco)' },
  { value: 'gl', text: 'Galician' },
  { value: 'gl-ES', text: 'Galician (Spain)' },
  { value: 'gu', text: 'Gujarati' },
  { value: 'gu-IN', text: 'Gujarati (India)' },
  { value: 'he', text: 'Hebrew' },
  { value: 'he-IL', text: 'Hebrew (Israel)' },
  { value: 'hi', text: 'Hindi' },
  { value: 'hi-IN', text: 'Hindi (India)' },
  { value: 'hr', text: 'Croatian' },
  { value: 'hr-BA', text: 'Croatian (Bosnia and Herzegovina)' },
  { value: 'hr-HR', text: 'Croatian (Croatia)' },
  { value: 'hu', text: 'Hungarian' },
  { value: 'hu-HU', text: 'Hungarian (Hungary)' },
  { value: 'hy', text: 'Armenian' },
  { value: 'hy-AM', text: 'Armenian (Armenia)' },
  { value: 'id', text: 'Indonesian' },
  { value: 'id-ID', text: 'Indonesian (Indonesia)' },
  { value: 'is', text: 'Icelandic' },
  { value: 'is-IS', text: 'Icelandic (Iceland)' },
  { value: 'it', text: 'Italian' },
  { value: 'it-CH', text: 'Italian (Switzerland)' },
  { value: 'it-IT', text: 'Italian (Italy)' },
  { value: 'ja', text: 'Japanese' },
  { value: 'ja-JP', text: 'Japanese (Japan)' },
  { value: 'ka', text: 'Georgian' },
  { value: 'ka-GE', text: 'Georgian (Georgia)' },
  { value: 'kk', text: 'Kazakh' },
  { value: 'kk-KZ', text: 'Kazakh (Kazakhstan)' },
  { value: 'kn', text: 'Kannada' },
  { value: 'kn-IN', text: 'Kannada (India)' },
  { value: 'ko', text: 'Korean' },
  { value: 'ko-KR', text: 'Korean (Korea)' },
  { value: 'kok', text: 'Konkani' },
  { value: 'kok-IN', text: 'Konkani (India)' },
  { value: 'ky', text: 'Kyrgyz' },
  { value: 'ky-KG', text: 'Kyrgyz (Kyrgyzstan)' },
  { value: 'lt', text: 'Lithuanian' },
  { value: 'lt-LT', text: 'Lithuanian (Lithuania)' },
  { value: 'lv', text: 'Latvian' },
  { value: 'lv-LV', text: 'Latvian (Latvia)' },
  { value: 'mi', text: 'Maori' },
  { value: 'mi-NZ', text: 'Maori (New Zealand)' },
  { value: 'mk', text: 'FYRO Macedonian' },
  { value: 'mk-MK', text: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)' },
  { value: 'mn', text: 'Mongolian' },
  { value: 'mn-MN', text: 'Mongolian (Mongolia)' },
  { value: 'mr', text: 'Marathi' },
  { value: 'mr-IN', text: 'Marathi (India)' },
  { value: 'ms', text: 'Malay' },
  { value: 'ms-BN', text: 'Malay (Brunei Darussalam)' },
  { value: 'ms-MY', text: 'Malay (Malaysia)' },
  { value: 'mt', text: 'Maltese' },
  { value: 'mt-MT', text: 'Maltese (Malta)' },
  { value: 'nb', text: 'Norwegian (Bokm?l)' },
  { value: 'nb-NO', text: 'Norwegian (Bokm?l) (Norway)' },
  { value: 'nl', text: 'Dutch' },
  { value: 'nl-BE', text: 'Dutch (Belgium)' },
  { value: 'nl-NL', text: 'Dutch (Netherlands)' },
  { value: 'nn-NO', text: 'Norwegian (Nynorsk) (Norway)' },
  { value: 'ns', text: 'Northern Sotho' },
  { value: 'ns-ZA', text: 'Northern Sotho (South Africa)' },
  { value: 'pa', text: 'Punjabi' },
  { value: 'pa-IN', text: 'Punjabi (India)' },
  { value: 'pl', text: 'Polish' },
  { value: 'pl-PL', text: 'Polish (Poland)' },
  { value: 'ps', text: 'Pashto' },
  { value: 'ps-AR', text: 'Pashto (Afghanistan)' },
  { value: 'pt', text: 'Portuguese' },
  { value: 'pt-BR', text: 'Portuguese (Brazil)' },
  { value: 'pt-PT', text: 'Portuguese (Portugal)' },
  { value: 'qu', text: 'Quechua' },
  { value: 'qu-BO', text: 'Quechua (Bolivia)' },
  { value: 'qu-EC', text: 'Quechua (Ecuador)' },
  { value: 'qu-PE', text: 'Quechua (Peru)' },
  { value: 'ro', text: 'Romanian' },
  { value: 'ro-RO', text: 'Romanian (Romania)' },
  { value: 'ru', text: 'Russian' },
  { value: 'ru-RU', text: 'Russian (Russia)' },
  { value: 'sa', text: 'Sanskrit' },
  { value: 'sa-IN', text: 'Sanskrit (India)' },
  { value: 'se', text: 'Sami (Northern)' },
  { value: 'se-FI', text: 'Sami (Northern) (Finland)' },
  { value: 'se-FI', text: 'Sami (Skolt) (Finland)' },
  { value: 'se-FI', text: 'Sami (Inari) (Finland)' },
  { value: 'se-NO', text: 'Sami (Northern) (Norway)' },
  { value: 'se-NO', text: 'Sami (Lule) (Norway)' },
  { value: 'se-NO', text: 'Sami (Southern) (Norway)' },
  { value: 'se-SE', text: 'Sami (Northern) (Sweden)' },
  { value: 'se-SE', text: 'Sami (Lule) (Sweden)' },
  { value: 'se-SE', text: 'Sami (Southern) (Sweden)' },
  { value: 'sk', text: 'Slovak' },
  { value: 'sk-SK', text: 'Slovak (Slovakia)' },
  { value: 'sl', text: 'Slovenian' },
  { value: 'sl-SI', text: 'Slovenian (Slovenia)' },
  { value: 'sq', text: 'Albanian' },
  { value: 'sq-AL', text: 'Albanian (Albania)' },
  { value: 'sr-BA', text: 'Serbian (Latin) (Bosnia and Herzegovina)' },
  { value: 'sr-BA', text: 'Serbian (Cyrillic) (Bosnia and Herzegovina)' },
  { value: 'sr-SP', text: 'Serbian (Latin) (Serbia and Montenegro)' },
  { value: 'sr-SP', text: 'Serbian (Cyrillic) (Serbia and Montenegro)' },
  { value: 'sv', text: 'Swedish' },
  { value: 'sv-FI', text: 'Swedish (Finland)' },
  { value: 'sv-SE', text: 'Swedish (Sweden)' },
  { value: 'sw', text: 'Swahili' },
  { value: 'sw-KE', text: 'Swahili (Kenya)' },
  { value: 'syr', text: 'Syriac' },
  { value: 'syr-SY', text: 'Syriac (Syria)' },
  { value: 'ta', text: 'Tamil' },
  { value: 'ta-IN', text: 'Tamil (India)' },
  { value: 'te', text: 'Telugu' },
  { value: 'te-IN', text: 'Telugu (India)' },
  { value: 'th', text: 'Thai' },
  { value: 'th-TH', text: 'Thai (Thailand)' },
  { value: 'tl', text: 'Tagalog' },
  { value: 'tl-PH', text: 'Tagalog (Philippines)' },
  { value: 'tn', text: 'Tswana' },
  { value: 'tn-ZA', text: 'Tswana (South Africa)' },
  { value: 'tr', text: 'Turkish' },
  { value: 'tr-TR', text: 'Turkish (Turkey)' },
  { value: 'tt', text: 'Tatar' },
  { value: 'tt-RU', text: 'Tatar (Russia)' },
  { value: 'ts', text: 'Tsonga' },
  { value: 'uk', text: 'Ukrainian' },
  { value: 'uk-UA', text: 'Ukrainian (Ukraine)' },
  { value: 'ur', text: 'Urdu' },
  { value: 'ur-PK', text: 'Urdu (Islamic Republic of Pakistan)' },
  { value: 'uz', text: 'Uzbek (Latin)' },
  { value: 'uz-UZ', text: 'Uzbek (Latin) (Uzbekistan)' },
  { value: 'uz-UZ', text: 'Uzbek (Cyrillic) (Uzbekistan)' },
  { value: 'vi', text: 'Vietnamese' },
  { value: 'vi-VN', text: 'Vietnamese (Viet Nam)' },
  { value: 'xh', text: 'Xhosa' },
  { value: 'xh-ZA', text: 'Xhosa (South Africa)' },
  { value: 'zh', text: 'Chinese' },
  { value: 'zh-CN', text: 'Chinese (S)' },
  { value: 'zh-HK', text: 'Chinese (Hong Kong)' },
  { value: 'zh-MO', text: 'Chinese (Macau)' },
  { value: 'zh-SG', text: 'Chinese (Singapore)' },
  { value: 'zh-TW', text: 'Chinese (T)' },
  { value: 'zu', text: 'Zulu' },
  { value: 'zu-ZA', text: 'Zulu (South Africa)' },
]

export default langcode
